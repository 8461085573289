import React, { Component } from "react";
import VideoNav from "./VideoNav";

class Video
 extends Component {
  render() {
    return (
        <VideoNav/>
    );
  }
}
 
export default Video
;
