import React, { Component } from "react";
import MusicNav from "./MusicNav";

class Music
 extends Component {
  render() {
    return (
      <div>
        <MusicNav/>
      </div>
    );
  }
}
 
export default Music
;