import React, { Component } from "react";
import PhotographsNav from "./PhotographsNav";

class Photographs
 extends Component {
  render() {
    return (
      <div>
        <PhotographsNav/>
      </div>
    );
  }
}
 
export default Photographs
;